.socialTitle {
  font-size: 20px !important;
}

.desConnectBtn {
  background-color: #cc0f08 !important;
  color: #fff !important;
  border-radius: 6px !important;
}

.connectBtn {
  background-color: #1c2d50 !important;
  color: #fff !important;
  border-radius: 6px !important;
}

.rect {
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M1 0 L0 1 L99 100 L100 99' fill='lightgray' /><path d='M0 99 L99 0 L100 1 L1 100' fill='lightgray' /></svg>");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%, auto;
  background-color: #ccc;
}

.rect2{
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><line x1='0' y1='0' x2='100' y2='100' stroke='lightgray' vector-effect='non-scaling-stroke'/><line x1='0' y1='100' x2='100' y2='0' stroke='lightgray' vector-effect='non-scaling-stroke'/></svg>");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%, auto;
}

.socialSinglesWrapper {
  width: 100%;
  padding-top: 60px;
  height: calc(100vh - 150px);
  overflow: scroll;
}

.socialCard {
  height: 280px;
  width: 280px;
  border-radius: 16px !important;
}

.disabledBtn {
  border-radius: 6px !important;
}

.radioEmpty {
  width: 25px;
  height: 25px;
  border-radius: 12.5px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  background-color: #E9E4E4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radioFull {
  width: 25px;
  height: 25px;
  border-radius: 12.5px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  background-image: linear-gradient(159deg, #fb8332 0%, #f8c332 112%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.social_connect {
  width: 31px;
  height: 31px;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15.5px;
  background-image: linear-gradient(152deg, #fb8332 2%, #f8c332 148%);
  position: absolute;
  top: -9px;
  right: -4px;
}

.socialLogo {
  width: 75px;
  height: 75px;
  border-radius: 9px;
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px #e7e7e7;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f8f7;
}

.postEditor {
  border: 1px solid #ccc;
  padding: 0px 12px;
  background-color: #fff;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  color: #000;
  font-size: 14px;
  font-family: "Nunito";
  min-height: 200px;
  max-height: 200px;
  line-height: 1.3;
  overflow-y: auto;
  &:focus {
    border-width: 3px !important;
  }
  &:hover {
    border: 1px solid #7c48c2;
  }
  & .public-DraftStyleDefault-block {
    margin: 0.4em 0 !important;
  }
}

.postWrapper {
  display: flex;
  flex-direction: column-reverse;

  & .rdw-editor-toolbar {
    background: none !important;
    border: none !important;
  }

  & .postEditorFocus {
    border: 2px solid #7c48c2 !important;
  }

  & .post-editor-toolbar {
    display: flex;
    justify-content: flex-end;
    gap: 0px;
    cursor: pointer;
    margin-top: 8px;
  }

  & .linkButtons {
    display: flex;
    gap: 10px;
    cursor: pointer;
  }

  & .rdw-option-wrapper {
    background: none;
  }

  & .rdw-emoji-modal {
    left: auto;
    right: 0px;
  }
  & .rdw-link-modal {
    left: auto;
    right: 0px;
  }
}
