.menuItemTIcon {
  min-width: 20px !important;
  margin-right: 11px;
}

.menuItemText {
  // font-family: Roboto !important;
  font-style: normal !important;;
  font-weight: 500 !important;;
  font-size: 18px !important;;
  line-height: 21px !important;;
}

.menuItemSubText {
  // font-family: Roboto !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #fff !important
}

.contentWrapper {
  // margin-left: 180px;
  transition: all 0.5s ease-in-out;
  flex-grow: 1;
}

.contentWrapperNoMenu {
  margin-left: 60px;
  transition: all 0.5s ease-in-out;
  flex-grow: 1;
}

.drawFullWidth {
  width: 300px;
  transition: all 0.5s ease-in-out;
  opacity: 1;
}

.formPopUpSize {
  width: 85vw
}

@media only screen and (max-width: 1200px) {
  .drawFullWidth {
    width: 300px;
  }

  .formPopUpSize {
    width: 95vw
  }
}

.drawZeroWidth {
  width: 0px !important;
  transition: all 0.5s ease-in-out;
  opacity: 0;
}

.mainPage {
  flex-grow: 1;
  display: 'flex';
  background-color: #F3F3F3;
}

.paragraphEditor {
  outline: 1px solid #ccc;
  padding: 4px 8px 2px;
  background-color: #f9f9f9;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  color: #000;
  line-height: 1.2;
  font-size: 14px;
  font-family: 'Nunito';
  margin-bottom:2px;
  &:focus {
    outline-width: 3px !important;
  }
  &:hover {
    outline: 1px solid #7C48C2;
  }

  & .DraftEditor-root {
    height: auto;
  }
}

.EditorFocus {
  outline: 2px solid #7C48C2 !important
}

.public-DraftStyleDefault-block {
  margin: 0.2em 0 !important;
}

.fieldLabel {
  font-family: Nunito;
  margin-Bottom: 20px;
  font-weight: normal; 
  font-size: 14px;
}

.parEditor {
  min-height: 150px;
  max-height: 300px;
  overflow-y: auto;
}

.singleBox { 
  margin: 10px 0 40px;
  background-color: #fff !important;
  box-shadow: 0px 17px 45px -31px rgba(5, 40, 72, 0.05);
  border-radius: 8px;   
}

h6.MuiTypography-root.singleTitle.MuiTypography-h6 {
  height: 48px;
  width: unset;
  color: white;
  // font-family: Roboto !important;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.28px;
  color:#36475F;
  line-height: 16.41px;
  display: inline;
}

.singleBoxTitle {
  vertical-align: middle;
    padding-left: 32px;
    padding-top: 24px;
    padding-bottom: 20px;
}

.singleWrapper {
  padding: 0px 32px 0px 0px;
}

.whitepaper {
  background-color: transparent !important;
  border-radius: 20px !important;

  div {
    background-color: transparent !important;
    border-radius: 20px !important;
  }
}

.image-container {
  & .image-hover {
   opacity: 0;
  }
  & .image-under-hover {
   opacity: 1 !important;
  }
 }

.image-container:hover {
 & .image-hover {
  opacity: 1;
 }
 & .image-under-hover {
  opacity: 0.3 !important;
 }
}


.imageList {
  // min-height: calc(100vh - 200px);
  // max-height: calc(100vh - 200px);
  max-height: 60vh;
  width: calc(100% - 40px);

  .gridList{
    width: 600px;
    height: 450px;
  }
  
  .imageListDrawerContainer {
    overflow: auto;
    background: #1F2634;
    height: 60vh;
    color: #fff;
  }

  .input {
		display: none;
	}

	.uploadbutton {
    background-color: #fff;
    color: #6c3dab;
		margin: 10px;
    &:hover {
      background-color: #6c3dab;
      color: #fff;
    }
	}

  .imageWrapper {
		overflow: scroll;
		height: 550px;
	}
}

.singlesWrapper {
  width:100%;
  height: calc(100vh - 150px);
    overflow: scroll;
}

.single-shadow {
  border-radius: 10px;
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.04);
}

.dot {
  height: 4px;
  width: 4px;
  background-color: #5AC4F3;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}

.nodot {
  width: 12px;
  display: inline-block;
  height: 100%;
  flex-flow: column;
}

.postMessageWrapper {
  padding: 30px 32px 30px;
  border: 1px solid #114894;
  border-radius: 8px;
  margin: 0 10px;
}

  .sd-input--disabled, .sd-input--disabled::placeholder {
    color: var(--foreground-disabled, rgba(22, 22, 22, 0.6)) !important
  }
  

  .singleSideBar {
    margin: 0px !important;

    & .MuiGrid-item {
      padding-top: 5px !important;
    }

    & .singleLeft {
      flex-direction: row;
    }
  
    & .paragrpah-editor-toolbar {
      display: none !important
    }
  
    & .singleLeftBox{
      width: 100%;
      flex-direction: row-reverse;
      align-items: center;
      border-right: none;
      padding: 0px;
      min-height: 50px
    }
  
    & .serviceList {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    & .copyContentBtn {
      display: none;
    }
  
    & .singleImageBox {
      padding-top: 10px;
      margin-bottom: 10px;
    }
  
    & .singleWrapper {
      margin: 15px 0px;
      padding: 0px 10px 10px;
      min-height: 0px;
    }
  
    & .singleImage {
      width: 40px;
      margin-left: 10px
    }
  
    & .singleDesc {
      margin-top: 0px;
      justify-content: flex-start;
      margin-left: 10px;
    }
  
    & .singleRightBox {
      padding-top: 0px;
  
      > div {
        margin: 0px 0px 0px
      }
    }

    & .singleHeader {
      display: none
    }
  
  }


.singleMobile {

    & .singleLeft {
      flex-direction: row;
    }

    & .paragrpah-editor-toolbar {
      display: none !important
    }

    & .singleLeftBox{
      width: 100%;
      flex-direction: row-reverse;
      align-items: center;
      border-right: none;
      padding: 0px
    }

    & .singleImageBox {
      padding-top: 10px;
      margin-bottom: 10px;
    }

    & .singleWrapper {
      margin: 15px 0px;
      padding: 0px 10px 10px;
    }

    & .singleImage {
      width: 40px;
      margin-left: 10px
    }

    & .singleDesc {
      margin-top: 0px;
      justify-content: flex-start;
      margin-left: 10px;
    }

    & .singleRightBox {
      padding-top: 16px;

      > div {
        margin: 0px 0px 10px
      }
    }

}

.mobileFormHeader {
  flex-direction: column !important;

  & .mobileFormHeaderButtons {
    justify-content: space-between;
    margin-top: 10px;
    > div {
      justify-content: "center";
      width:50%;
    }
  }
}

@keyframes glowing {
  0% {
    background-color: #2ba805;
    box-shadow: 0 0 5px #2ba805;
  }
  50% {
    background-color: #49e819;
    box-shadow: 0 0 20px #49e819;
  }
  100% {
    background-color: #2ba805;
    box-shadow: 0 0 5px #2ba805;
  }
}

body:has(#createForm:hover) #formBtn {
  // animation: glowing 1300ms infinite;
  outline: 2px solid #6C3DAB;
}

body:has(#createShare:hover) #shareBtn {
  outline: 2px solid #6C3DAB;
}

body:has(#createAi:hover) #aiBtn {
  outline: 2px solid #6C3DAB;
}

.compDescription {

  p {
    margin: 0px;
  }
}