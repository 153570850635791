.packageParam {

  div:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }

}

.priceDivSelected {
  transform: translateY(-6px);
  background: #F7F4FB !important;
  border: solid 2px #6c3dab !important
}

.priceDiv:hover {
  transition: 0.5s;
  background: rgba(0, 145, 248, 0.05);
  transform: translateY(-6px)
}

.priceSwitch {
  background: linear-gradient(140.1deg, rgba(251, 131, 50, 0.1) 4.37%, rgba(248, 195, 50, 0.1) 96.9%);
  border-radius: 100px;
  font-weight: 700;
  width:243px;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

.priceSwitch li button {
  padding: 5px 35px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  border-radius: 100px;
  font-weight: 400;
  border: none;
  cursor: pointer;
  background: transparent;
  &:hover {
    color: #FB8332
  }
}

.priceSwitch li button.active {
  background: linear-gradient(140.1deg, #9747ff 4.37%,#27094e 96.9%) !important;
  color: #fff !important;
  &:hover {
    color: #fff !important;
  }
}