.sideMenu:hover {

 & .sideButton {
   display: none;
 }

  & .sideButtons {
    display: flex;
    opacity: 1;
    width:30px;
  }

}