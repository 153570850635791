.My-projects {
  height: 22px;
  font-family: Nunito;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}

.tableHeader {
    font-family: Nunito;
    font-size: 16px;
    font-weight: 500;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.project-dashboard-menu {
  gap: 15px;
  font-size: 16px;
}

.textColorGrad {
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent;
}

.templatePreview {

  .sd-progress-buttons__list-container {
    display: none;
  }

  *:focus {
    outline: none;
  }
  .sd-page {
    // max-width: 80% !important;
    margin: 0 auto;
  }

  .sd-body.sd-body--static {
    max-width: 80% !important;
    margin: 0 auto;
  }

}

.emailShareBox {

  textarea {
    font-family: Nunito;
    font-size: 14px;
    font-weight: normal
  }

}

body #clientSurvey {
  // --primary: #1ab7fa;
  // --primary-light: rgba(26, 183, 250, 0.1);
  // --secondary: #1ab7fa;
  // --background: rgb(245, 248, 247);
  --background-dim: rgb(245, 248, 247);
  // --background-dim-light: #4d4d4d;
  // --foreground: rgb(245, 248, 247)

  .sd-page {
    max-width: 100% !important;
    margin: 0 auto;
  }
  .sd-body {
    max-width: 60% !important;
    margin: 0 auto;
    // margin: 0 auto;
  }

  
  /* Hide object selector */  
  .sd-page__title {  
    display: none;  
  }  


  @media only screen and (max-width: 900px) {
    .sd-page {
      max-width: 90% !important;
      padding: 0px;
    }

    .sd-body {
      max-width: 95% !important;
      margin: 0 auto !important
      // margin: 0 auto;
    }

    .sd-body {
      margin: 5px
    }
  }
}

#lynxi_brief_editor {
  .svc-page {
    width: 85% !important;
    margin: 0 auto;
  }
  .svd-simulator-content {
    width: 85% !important;
    margin: 0 auto;
  }
}

.fill_stage {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin: 0 0px 0 0;
  padding: 5px 10px 4px;
  background-image: linear-gradient(159deg, #fb8332 0%, #f8c332 112%);
  display: flex;
  color:#fff;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  font-weight: 800;
}

.empty_stage {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  font-size: 10px;
  margin: 0 0px 0 0;
  padding: 5px 10px 4px;
  background-color: #e3e3e3;
  display: flex;
  justify-content: center;
  align-items: center;
}


@media only screen and (max-width: 1400px) {
  .project-dashboard-menu {
    gap: 10px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 1200px) {
  .project-dashboard-menu {
    gap: 10px;
    font-size: 14px;
  }
}
