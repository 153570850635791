.lynxi{
  // width: 160px;
  // height: 45px;
  // margin: 3px 1341px 29px 0;
  // font-family: Nunito;
  font-family: 'DM Sans';
  font-size: 34.2px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  // letter-spacing: 1.5px;
  color: #7f49c3;
}

.oval {
  width: 46px;
  height: 46px;
  border-radius: 50px;
  padding: 10px 10px 10px 9px;
  background-image: linear-gradient(143deg, #3023ae -60%, #c86dd7 102%);
  cursor: pointer;
}

.gn {
  width: 27px;
  height: 26px;
  font-family: Nunito;
  font-size: 19.2px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.77px;
  justify-content: center;
  display: flex;
  color: #fff;
}

.shape {
  width: 93px;
  height: 93px;
  margin: 64px 21px 0 271px;
  box-shadow: 0 5px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #000;
}


