


h2 {
  margin: 0;
}

.drag-handle {
  display: inline-block;
  cursor: move;
  width: 20px;
  height: 20px;
}


.draggable-category {
  display: flex;
  align-items: flex-start;
  gap: 10px;

  & .drag-handle {
    margin-top: 10px;
  }
}

.draggable {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 8px 5px 8px 0px;

  & .drag-handle {
    margin-top: 0px;
  }
}


.category-container {
  width: 90%;
}

.item {
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 3px;
}
