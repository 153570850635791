.arrowRight {
  right: 11px;
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  top: 50% !important;
}

.arrowLeft {
  left: -6px;
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  top: 50% !important;
}

.arrowBottom {
  left: -6px;
  bottom: -5px !important;
}

.arrowUp {
  left: 225px !important;
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  top: 20px !important
}

.arrowDown {
  left: 225px !important;
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  bottom: -5px !important
}

.notificationArrowUp {
  left: 225px !important;
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  top: 10px !important
}

.commentPopper {

  // &[data-popper-placement*="right"]  {
  //   & .commentPaper {
  //     margin-right: 0px;
  //     margin-left:  10px;
  //   }

  //   & .arrowComment {
  //     left: -4px;
  //     border-left: 1px solid rgba(0, 0, 0, 0.3);
  //     top: 30px !important;
  //   };
  // };

  &[data-popper-placement*="right-start"]  {
    & .commentPaper {
      margin-right: 0px;
      margin-left:  20px;
      z-index: 10;
    }

    & .arrowComment {
      left: 7px;
      border-left: 1px solid rgba(0, 0, 0, 0.3);
      top: 30px !important;
      z-index: 8;
    };

    & .commentOver {
      margin-left: 20px,
    }
  };

  &[data-popper-placement*="bottom"]  {
    & .commentPaper {
      margin-right: 0px;
      margin-left:  0px;
      // margin-top:  35px;
      margin-top:  -5px;
      z-index: 10;
    }

    & .arrowComment {
      left: calc( 50% - 25px) !important;
      // top: 35px !important;
      // left: calc( 50% - 12px) !important;
      border-left: 1px solid rgba(0, 0, 0, 0.3);
      top: -5px !important;
      z-index: 8;
    };

  };

  &[data-popper-placement*="top"]  {
    & .commentPaper {
      margin-right: 0px;
      margin-left:  0px;
      margin-bottom: 10px;
      // margin-bottom: 15px;
      z-index: 10;
    }

    & .arrowComment {
      // left: calc( 50% - 12px) !important;
      left: calc( 50% - 25px) !important;
      border-left: 1px solid rgba(0, 0, 0, 0.3);
      // bottom: -1px !important;
      bottom: -4px !important;
      z-index: 8;
    };

  };

  &[data-popper-placement*="right-end"]  {
    & .commentPaper {
      margin-right: 0px;
      margin-left:  20px;
      z-index: 10;
    }

    & .arrowComment {
      left: 7px;
      top:auto !important;
      border-left: 1px solid rgba(0, 0, 0, 0.3);
      bottom: 4px !important;
      z-index: 8;
    };

    & .commentOver {
      margin-left: 20px,
    }
  };

  &[data-popper-placement*="left-start"]  {
    & .commentPaper {
      margin-right: 0px;
      margin-left:  0px;
      z-index: 10;
    }

    & .arrowComment {
      right: 7px;
      border-right: 1px solid rgba(0, 0, 0, 0.3);
      top: 30px !important;
      z-index: 8;
    };
  };

  &[data-popper-placement*="left-end"]  {
    & .commentPaper {
      margin-right: 0px;
      margin-left:  0px;
      z-index: 10;
    }

    & .arrowComment {
      right: 7px;
      top:auto !important;
      border-right: 1px solid rgba(0, 0, 0, 0.3);
      bottom: 4px !important;
      z-index: 8;
    };
  };

  // &[data-popper-placement*="left"]  {
  //   & .commentPaper {
  //     margin-right: 10px;
  //     margin-left:  0px;
  //   }
  //   & .arrowComment {
  //     right: 11px;
  //     border-right: 1px solid rgba(0, 0, 0, 0.3);
  //     top: 50% !important;
  //   };
  // };
}

.helperPopper {
  z-index: 2000;
  &[data-popper-placement*="right"]  {
    & .helperPopperPage {
      margin-right: 0px;
      margin-left:  10px;
    }

    & .helperArrowComment {
      left: -16px;
      border-left: 1px solid rgba(0, 0, 0, 0.3);
      top: 50% !important;
    };
  };

  &[data-popper-placement*="left"]  {
    & .helperPopperPage {
      margin-right: 10px;
      margin-left:  0px;
    }
    & .helperArrowComment {
      right: 1px;
      border-right: 1px solid rgba(0, 0, 0, 0.3);
      top: 50% !important;
    };
  };

  &[data-popper-placement*="top"]  {
    & .helperPopperPage {
      margin-right: 0px;
      margin-left:  0px;
      margin-bottom: 10px;
    }
    & .helperArrowComment {
      right: 50%;
      border-right: 1px solid rgba(0, 0, 0, 0.3);
      bottom: -16px
    };
  };

  &[data-popper-placement*="bottom"]  {
    & .helperPopperPage {
      margin-right: 0px;
      margin-left:  0px;
    }
    & .helperArrowComment {
      right: 50%;
      border-right: 1px solid rgba(0, 0, 0, 0.3);
      top: 0;
    };
  };

  &[data-popper-placement*="bottom-end"]  {
    & .helperPopperPage {
      margin-right: 0px;
      margin-left:  0px;
    }
    & .helperArrowComment {
      right: 15%;
      border-right: 1px solid rgba(0, 0, 0, 0.3);
      top: 0;
    };
  };
}

.basePopper {
  &[data-popper-placement*="right"]  {
    & .basePopperPage {
      margin-right: 0px;
      margin-left:  20px;
    }

    & .basePopperArrowComment {
      left: 4px;
      border-left: 1px solid rgba(0, 0, 0, 0.3);
      top: 50% !important;
    };
  };

  &[data-popper-placement*="left"]  {
    & .basePopperPage {
      margin-right: 20px;
      margin-left:  0px;
    }
    & .basePopperArrowComment {
      right: 20px;
      border-right: 1px solid rgba(0, 0, 0, 0.3);
      top: 50% !important;
    };
  };

  &[data-popper-placement*="top"]  {
    & .basePopperPage {
      margin-right: 0px;
      margin-left:  0px;
      margin-bottom: 20px;
    }
    & .basePopperArrowComment {
      right: 50%;
      border-right: 1px solid rgba(0, 0, 0, 0.3);
      bottom: 5px
    };
  };

  &[data-popper-placement*="top-end"]  {
    & .basePopperPage {
      margin-right: 0px;
      margin-left:  0px;
      margin-bottom: 20px;
    }
    & .basePopperArrowComment {
      right: 15%;
      border-right: 1px solid rgba(0, 0, 0, 0.3);
      bottom: 5px
    };
  };

  &[data-popper-placement*="top-start"]  {
    & .basePopperPage {
      margin-right: 0px;
      margin-left:  0px;
      margin-bottom: 20px;
    }
    & .basePopperArrowComment {
      right: 85%;
      border-right: 1px solid rgba(0, 0, 0, 0.3);
      bottom: 5px
    };
  };

  &[data-popper-placement*="bottom"]  {
    & .basePopperPage {
      margin-right: 0px;
      margin-left:  0px;
      margin-top: 20px;
    }
    & .basePopperArrowComment {
      right: 50%;
      border-right: 1px solid rgba(0, 0, 0, 0.3);
      top: 20px;
    };
  };

  &[data-popper-placement*="bottom-end"]  {
    & .basePopperPage {
      margin-right: 0px;
      margin-left:  0px;
      margin-top: 20px;
    }
    & .basePopperArrowComment {
      right: 15%;
      border-right: 1px solid rgba(0, 0, 0, 0.3);
      top: 20px;
    };
  };

  &[data-popper-placement*="bottom-start"]  {
    & .basePopperPage {
      margin-right: 0px;
      margin-left:  0px;
      margin-top: 20px;
    }
    & .basePopperArrowComment {
      right: 85%;
      border-right: 1px solid rgba(0, 0, 0, 0.3);
      top: 20px;
    };
  };
}

// .arrowComment {
//   top: 50% !important;
//   position: absolute;
//   font-size: 7;
//   width: 3em;
//   height: 3em;
//   &::before {
//     content: "";
//     margin: auto;
//     display: block;
//     width: 0;
//     height: 0;
//     border-style: solid;
//   }
// }