.a{
  position:absolute;
  width:20px;
  background:#6C3DAB;
  height:20px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -o-border-radius: 10px;
}

.c{
  position:absolute;
  width:20px;
  background:rgb(166, 3, 3);
  height:20px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -o-border-radius: 10px;
}

.b{
  position: absolute;
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -o-border-radius: 5px;
}


.glowing-circle {
  width: 20px;
  height: 20px;
  border-radius:50%;
  background-color: #fff;
  -webkit-animation: glowing 1s ease-in-out infinite alternate;
  -moz-animation: glowing 1s ease-in-out infinite alternate;
  animation: glowing 1s ease-in-out infinite alternate;
}

@keyframes glowing {
  from {
    box-shadow: 0 0 3.2px #fff, 0 0 6.4px #fff, 0 0 9.6 #f0f, 0 0 12.8px #0ff, 0 0 16px #e60073, 0 0 19.2px #e60073, 0 0 22.4px #e60073;
  }
  to {
    box-shadow: 0 0 6.4px #fff, 0 0 9.6px #ff4da6, 0 0 12.8px #ff4da6, 0 0 16px #ff4da6, 0 0 19.2px #ff4da6, 0 0 22.4px #ff4da6, 0 0 25.6px #ff4da6;
  }
}